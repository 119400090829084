import * as React from "react"
import { FC } from "react"
import { Grid, Text, Heading, Box, Container, SxStyleProp } from "theme-ui"

import { Footer } from "components/Footer"
import { GridOverlay } from "components/GridOverlay"
import { HeroCareerLadder } from "components/HeroCareerLadder"
import { Ladder } from "components/Ladder"
import { Layout } from "components/Layout"
import { SEO } from "components/Seo"

export const CareerLadderPage: FC = () => {
  const sectionMargin: SxStyleProp = {
    mb: [10, 12, null, 13, 14],
  }

  return (
    <Layout>
      <GridOverlay />
      <SEO title="Career Ladder" location="/career-ladder" />
      <Box as="article">
        <HeroCareerLadder />
        <Container>
          <Grid variant="base"></Grid>
        </Container>
        <Container>
          <Grid variant="base">
            <Text
              sx={{
                gridColumn: "7/13",
                fontSize: [2, 3, 2, 3],
                textAlign: "center",
                mt: 6,
                mb: 12,
                color: "textMutedMore",
              }}
            >
              Illustration by Yaxin Bai
            </Text>
            <Container variant="pipe">
              <Box as="section" sx={sectionMargin}>
                <Heading variant="h2">Overview</Heading>
                <Text as="p" variant="body">
                  The ladder is broken down into three different parts:
                </Text>
                <Box as="ol" variant="layout.listDecimal">
                  <Box as="li">
                    <Text as="p" variant="body">
                      <strong>Technical excellence:</strong> we value people
                      with a strong technical core, and we won't compromise with
                      this when we promote people!
                    </Text>
                  </Box>
                  <Box as="li">
                    <Text as="p" variant="body">
                      <strong>Initiative:</strong> a big part of operating at a
                      higher level is not just doing what you're told, but to
                      identify new value for the team/department/company.
                    </Text>
                  </Box>
                  <Box as="li">
                    <Text as="p" variant="body">
                      <strong>Influence:</strong> the best way to be a 10x
                      engineer is to make 10 other engineers 2x more productive.
                    </Text>
                  </Box>
                </Box>
                <Text as="p" variant="body">
                  How are promotions between levels done going forward?
                  Generally, the intent is for your manager to make an
                  assessment and see what level you've been{" "}
                  <em>consistently performing at for the last six months</em>.
                  If it's determined that salary wise or title wise you're at
                  one place, but you're performing according to a higher level,
                  then your comp/title will change to reflect the new level that
                  you've been showing that you're on.
                </Text>
                <Text as="p" variant="body">
                  What does it mean to consistently perform at a certain level?
                  It means hitting{" "}
                  <em>
                    essentially all points on that level and all levels below
                  </em>
                  . This means that someone on a lower level might hit some
                  points at a higher level. But it's not enough to hit some of
                  those points in order to get a promotion: you need to make
                  sure to hit almost all of them. There will be some managerial
                  discretion here and of course this system isn't meant to trump
                  common sense in terms of who is crushing it.
                </Text>
                <Text as="p" variant="body">
                  There's a lot of stuff in this doc, but the most important
                  thing is that delivering business{" "}
                  <em>value will be what’s ultimately rewarded at Better</em>.
                  Since it’s very hard to estimate business value directly, we
                  rely on a number of “proxies” for it, which are the things
                  that are articulated in the career ladder. Common sense around
                  who delivered most value will always trump anything on the
                  career ladder.
                </Text>
              </Box>
            </Container>
          </Grid>
        </Container>
        <Box sx={{ ...sectionMargin, pointerEvents: "none" }}>
          <Ladder />
        </Box>
        <Footer />
      </Box>
    </Layout>
  )
}

export default CareerLadderPage
