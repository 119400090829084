/** @jsx jsx */
import * as React from "react"
import { FC, useCallback } from "react"
import { jsx } from "theme-ui"
import { useSpring } from "react-spring"

import { Hero } from "components/Hero"
import { HeroTextLockup } from "components/HeroTextLockup"
import { ManOnLadder } from "illustrations/ManOnLadder"

export const HeroCareerLadder: FC = ({}) => {
  const [{ xy }, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))

  const calc = (x: number, y: number) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ]

  const onMove = useCallback(
    ({ clientX: x, clientY: y }: { clientX: number; clientY: number }) =>
      void set({ xy: calc(x, y) }),
    []
  )

  return (
    <Hero
      onMouseMove={onMove}
      alignImage="right"
      image={
        <ManOnLadder
          sx={{
            width: "100%",
            height: "auto",
          }}
          xy={xy}
        />
      }
      imageSxOverride={{ gridColumn: ["2/12", null, "7/13"] }}
      textSxOverride={{ gridColumn: ["span 12", null, "1/7", "1/7"] }}
    >
      <HeroTextLockup
        headingArray={["Career Ladder"]}
        paragraph={`Here are software engineer levels at Better together with expectations of what you need to do in order to operate at those levels.`}
      />
    </Hero>
  )
}
