import React, { FC, useState, useRef, useLayoutEffect, useEffect } from "react"
import { animated, useSpring, config } from "react-spring"
import { useMedia } from "react-use"
import { Container, Grid, Heading, Box, Text } from "theme-ui"

import { LadderContent } from "./Content"
import { Toggle } from "./Toggle"
import { IPropsLevel, ToggleableSummary } from "./ToggleableSummary"

const AnimatedGrid = animated(Grid)

export const Ladder: FC = () => {
  const [selected, setSelected] = useState<IPropsLevel>(null)
  const [isManager, setIsManager] = useState<boolean>(false)
  const [panelHeight, setPanelHeight] = useState<number>(0)
  const [summaryHeight, setSummaryHeight] = useState<number>(0)

  const [{ height: animatedHeight }, animate] = useSpring(
    {
      height: 0,
      config: config.slow,
    },
    []
  )

  const scrollRef = useRef<HTMLDivElement>(null)
  const isMobile = useMedia("(max-width: 767px)")

  function scrollToLadder() {
    const adjust = isMobile ? 112 : 148
    const { top } = scrollRef.current?.getBoundingClientRect()
    const getOffsetTop = top + window.scrollY - adjust

    // scroll page to top of ladder step
    window.scrollTo({
      top: getOffsetTop,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    // resize section to height of summary or height of content
    // make sure there is always enough height to fit whichever is higher between:
    // - close summary
    // - open summary
    // - opened visible content

    let animatedHeight
    if (selected === null) {
      animatedHeight = summaryHeight
    } else {
      animatedHeight = summaryHeight > panelHeight ? summaryHeight : panelHeight
    }
    animate({ height: animatedHeight })
  }, [summaryHeight, panelHeight, selected, isManager])

  useLayoutEffect(() => {
    if (selected !== null) {
      scrollToLadder()
    }
  }, [selected])

  return (
    <Box sx={{ position: "relative" }}>
      <Container>
        <Grid variant="base">
          <Box
            variant="layout.pipe"
            sx={{
              whiteSpace: "nowrap",
              textAlign: "left",
              pointerEvents: "all",
            }}
          >
            <Heading
              variant="h1"
              sx={{
                letterSpacing: "tight5",
                mb: [3],
              }}
            >
              The ladder
            </Heading>
            <Heading variant="h4">
              <Toggle
                isManager={isManager}
                onClick={() => setIsManager((prev) => !prev)}
              />
            </Heading>
            <Text
              sx={{
                color: "textMutedMore",
                opacity: selected === null ? 1 : 0,
                transition: "opacity 0.5s var(--animation-bezier)",
                transitionDelay: selected === null ? "1s" : "0s",
                fontSize: [2, 3, 3, 4],
                my: [3, 4, null, 5, 6],
              }}
            >
              Click on the titles in the ladder to learn more
            </Text>
          </Box>
        </Grid>
      </Container>
      <Box
        ref={scrollRef}
        sx={{
          pointerEvents: "none",
          height: [144, null, 100, null, null, 144],
          position: "absolute",
        }}
      ></Box>
      <Container
        sx={{
          paddingY: "20vw",
          marginY: "-20vw",
          overflow: selected === null ? "hidden" : "unset",
        }}
      >
        <AnimatedGrid style={{ height: animatedHeight }} variant="base">
          <ToggleableSummary
            selected={selected}
            setSummaryHeight={setSummaryHeight}
            setIsManager={setIsManager}
            setSelected={setSelected}
            isManager={isManager}
            sx={{
              gridRow: "1/2",
              gridColumn: "1/13",
              position: "sticky",
              top: selected === null ? undefined : [256, 352],
            }}
          />
          <LadderContent
            isManager={isManager}
            selected={selected}
            setPanelHeight={setPanelHeight}
            sx={{
              gridRow: "1/2",
              gridColumn: "1/13",
            }}
          />
        </AnimatedGrid>
      </Container>
    </Box>
  )
}
