import * as React from "react"
import { FC } from "react"
import { Text, Box, Heading } from "theme-ui"

import { Arrow } from "components/Arrow"
import { LevelHeading } from "../LevelHeading"

export const Level7Manager: FC = () => {
  return (
    <>
      <LevelHeading>
        Engineering Manager
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L7
        </Box>
      </LevelHeading>
      <Box as="ul" variant="layout.ladderList">
        <Box as="li">
          <Heading variant="h4">Typical Experience</Heading>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Internal promotions without prior engineering management
                experience require L6 Senior Software level as well a complete
                “Senior Software Engineer <Arrow />
                Engineering Manager” cycle
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                External hires should have at least 2 years of experience
                managing engineers
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Heading variant="h4">Scope & Impact</Heading>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Manages an independent team (pod) of 3-10 engineers
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Ownership of a segment of over Better.com platform including
                multiple complex features, tools and/or infrastructure
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Significant technical contributor, expected to spend ~50% of
                time as IC, decreasing towards 20-30% as the number of direct
                reports grows above 5 and falling to about 0% with 8-10 reports
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Heading variant="h4" sx={{ mb: [7, 8, 7, 8, 9] }}>
            Competencies
          </Heading>
          <Text variant="ladderEyebrow">Technical Excellence</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Understands the systems and products owned by their pod, how
                customers interact with them, and how they fit into the larger
                business. Applies this understanding to identify and prioritize
                key net-new improvements, while ensuring the team continues to
                deliver on important initiatives
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Communicates effectively with team members and stakeholders,.
                Balances expectations, priorities and individual accountability
                based on team/technical limitations and tradeoffs. Proactively
                communicates with stakeholders, senior manager, to set and
                re-set expectations when appropriate
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Understands the team's business and product domain well,
                understands adjacent domains to their team and how to
                collaborate to get work accomplished, and partners with them to
                make sure the larger system is being considered in business and
                technical decisions
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Up to date on the current technology landscape and relevant
                trends. Works with others in their technical area to grow their
                understanding of the team's domain and technology. Leverages
                this knowledge to drive better decision making
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Text variant="ladderEyebrow">Prioritization & Execution</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Identify the types of problems that can be delivered based on
                the team composition & come up with a plan on how to adjust
                expectations with stakeholders or grow the team with the right
                composition
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Is directly responsible for the career development of their team
                members, continually challenging them to raise the bar.
                Addresses areas of underperformance. Acts as a hiring manager,
                taking direct part in the hiring process and helping to ensure
                that they bring a wide variety of experience into their team
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Partners with product and business leaders to create strategies
                that align their pods with the short and long term technical and
                business needs of the company
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Is keenly focused on creating ROI in the short and mid terms.
                Appropriately pivots based on new learnings and changes in
                context
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Text variant="ladderEyebrow">Coaching & Development</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Proactively and constructively provides feedback to immediate
                peers & their teams. Seeks guidance and / or support from
                manager as appropriate
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Drives regular 1-1s with direct reports. Provides active
                coaching and feedback to support day-to-day execution and growth
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Proactively flags tactical risks to projects to manager, along
                with plan(s) to resolve. Effectively surfaces concerns &
                questions from the team
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Supports effective performance management culture within team
                and direct reports
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Text variant="ladderEyebrow">Leadership & Collaboration</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Partners with product and business on near-term vision and
                month-to-month planning for team
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Uses data-driven arguments and business cases to craft project
                plans and pod-level roadmaps with product partners
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Has deep awareness and expertise of at least one workstream
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Text as="p" variant="small">
        Since EM is L7, being promoted from L6 SSE, is an actual promotion with
        a new level
      </Text>
    </>
  )
}
