import * as React from "react"
import { FC } from "react"
import { Text, Box, Heading } from "theme-ui"

import { LevelHeading } from "../LevelHeading"

export const Level8IC: FC = () => {
  return (
    <>
      <LevelHeading>
        Senior Staff Software Engineer
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L8
        </Box>
      </LevelHeading>
      <Box sx={{ mb: [10, 12, null, 13, 14] }}>
        <Box as="ul" variant="layout.ladderList">
          <Box as="li">
            <Text variant="ladderEyebrow">
              Technical excellence (Generalist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Consistent track record of great technical judgement to the
                  point where they have full autonomy to spend their time on
                  whatever they think is most important for the business
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Actively contributing to the core feature, platform,
                  infrastructure, that's shared across the tech team (backend
                  eg. AWS, RBAC, EventBus, CI) (frontend eg: apollo, graphql,
                  design systems)
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Strong opinions and knowledge about the current technology
                  landscape, can argue for/against modern technologies and their
                  place at this company
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Strong understanding of how to structure communication that
                  best suited for the audience - whether it's an engineer,
                  product, executive, or a customer
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Great at debugging complex outages and acts as an incident
                  commander, has the foresight to bake in solutions that will
                  prevent issues from happening ever again in the future;
                  focuses on robustness, observability, and measurability in the
                  context of key SRE KPIs (MTTR, MTBF, etc.)
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Seen as an authority on major parts of the codebase and
                  various technologies
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Able to do trade-off analysis to understand the scaling,
                  reliability &amp; security concerns of introducing the other
                  technology it Better to solve a large class of problem
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Advanced understanding of security threats, proactively
                  identifies larger classes of attack vectors, and sets aside
                  time to protect the company
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text variant="ladderEyebrow">
              Technical excellence (Specialist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Consistent track record of great technical judgement to the
                  point where they have full autonomy to spend their time on
                  whatever they think is most important for the business
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Synthesizes inexact problem statements into a technology
                  strategy, especially those pertaining to a highly specialized
                  area
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Deep Technical Knowledge in a particular technology and/or
                  business area
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Strong understanding of how to structure communication that
                  best suited for the audience - whether it's an engineer,
                  product, executive, or a customer
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Great at debugging complex outages and acts as an incident
                  commander, has the foresight to bake in solutions that will
                  prevent issues from happening ever again in the future;
                  focuses on robustness, observability, and measurability in the
                  context of key SRE KPIs (MTTR, MTBF, etc.)
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Specialist often have a very particular skill set that makes
                  them have very detailed knowledge of Better (Business Context,
                  Platform Context, Infrastraucture Context)
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Up-to-date on the trending changes on group of related
                  technical industries
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Advanced understanding of one specific industry technology
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text variant="ladderEyebrow">Initiative (Generalist)</Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Promotes best practices to prevent outages from happening,
                  reduces likelihood of incident recurrence
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Set and promotes best practices
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Improving the developer experience and expertise through
                  documentation, mentoring, teaching, and consensus building
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text variant="ladderEyebrow">Initiative (Specialist)</Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Promotes best practices to prevent outages from happening,
                  reduces likelihood of incident recurrence
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Set and promotes best practices
                </Text>
              </Box>

              <Box as="li">
                <Text as="p" variant="body">
                  Develops tools, libraries, services, or processes that reduce
                  engineering cost of delivery across common problem areas
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text as="p" variant="ladderEyebrow">
              Influence (Generalist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Collaborates with peers to influence the Group's technical
                  decision making in the context of business objectives
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Solid understanding of the product area and strategy covering
                  the team/multiple pods. Trusted as the solution provider for
                  complex business problems across teams. Working with eng,
                  product and business folks provide the sound tech decision
                  making, trade off plans, to solve for the business challenge
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Not just aware of the business priorities, but actively
                  promotes knowledge to other people about what's important for
                  the business and how the work fits into that bigger picture
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Create content that achieves significant external recognition,
                  such as a paper that is cited or a conference presentation
                  that is often mentioned
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Identifies opportunities, defines processes, builds technology
                  to support self-service platforms
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Help evaluate and sell very senior candidates by digging deep
                  technically to extract the right signal so the right decision
                  can be made
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text as="p" variant="ladderEyebrow">
              Influence (Specialist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Trusted to have excellent technical judgement to the point
                  where they have full autonomy to spend their time on whatever
                  they think is most important for the business (scoped to the
                  Group)
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Solid understanding of the product area and strategy covering
                  the team/multiple pods. Trusted as the solution provider for
                  complex business problems across teams. Working with eng,
                  product and business folks provide the sound tech decision
                  making, trade off plans, to solve for the business challenge
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Sets technical north star for specific pieces of
                  infrastructure, and gets buy-in from all pods with in a group.
                  Coordinates implementation efforts as a program across
                  different pods
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Create content that achieves significant external recognition,
                  such as a paper that is cited or a conference presentation
                  that is often mentioned
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Identifies opportunities, defines processes, builds technology
                  to support self-service platforms
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Help evaluate and sell very senior candidates by digging deep
                  technically to extract the right signal so the right decision
                  can be made
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Heading variant="h3" sx={{ mb: [6, 7, 5, 9, 10] }}>
        Path to Engineering Manager
      </Heading>
      <Heading variant="h4">Hired as Senior Engineering Manager</Heading>
      <Box as="ul" variant="layout.ladderListInner">
        <Box as="li">
          <Text as="p" variant="body">
            Should have managed teams of at least 15 engineers for a minimum of
            5 years
          </Text>
        </Box>
        <Box as="li">
          <Text as="p" variant="body">
            Assigned 4-6 direct reports immediately upon joining
          </Text>
        </Box>
        <Box as="li">
          <Text as="p" variant="body">
            Expected to grow team by 2-3 times in the first 12 months and groom
            / hire at least two EMs
          </Text>
        </Box>
      </Box>
    </>
  )
}
