import * as React from "react"
import { FC } from "react"
import { Text, Box, Heading } from "theme-ui"

import { LevelHeading } from "../LevelHeading"

export const Level8Manager: FC = () => {
  return (
    <>
      <LevelHeading>
        Senior Engineering Manager
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L8
        </Box>
      </LevelHeading>
      <Box as="ul" variant="layout.ladderList">
        <Box as="li">
          <Heading variant="h4">Typical Experience</Heading>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                At least 2 years of Engineering Manager experience at Better or
                at least 4 years as Senior Engineering Manager outside of Better
                managing a team of 20+ people and 3+ engineering managers if
                joining at Senior Engineering Manager level
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Promoted after showing constant and reliable performance as
                Engineering Manager over a course of at least 2 years delivering
                several complex projects, and building and mentoring solid range
                of direct reports
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Heading variant="h4">Scope & Impact</Heading>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Directly manages individual contributors and/or engineering
                managers across multiple pods (10+ engineers){" "}
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Ownership of multiple complex and related segments of
                Better.com’s platform
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Manages up to three pods inside of the same squad with each pod
                consisting of an Engineering Manager with 3-8 direct reports
                each and up to 3 L8/L9 Senior Software Engineers reporting to
                Senior Engineering Manager directly
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Technical contributions generally insignificant, but capable of
                contributing if necessary
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Heading variant="h4" sx={{ mb: [7, 8, 7, 8, 9] }}>
            Competencies
          </Heading>
          <Text variant="ladderEyebrow">Technical Excellence</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Understands all of the systems and products owned by their
                broader team, how customers interact with them, and how they fit
                into the larger business. Has a deep understanding for how they
                will evolve over time and applies this to their regular decision
                making
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Communicates effectively across teams and leaders, adjusting
                their approach to fit their audience. Proactively communicates
                with stakeholders to set and re-set expectations when
                appropriate
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Understands the team's business and product domain well, with a
                clear understanding with related domains/engineering areas, and
                how their work interconnects. Partners with other teams to
                properly understand and consider the larger system before making
                business and technical decisions
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Actively researches and understands current relevant engineering
                trends and selectively identifies the ones could be beneficial
                to their teams. Enables their to appropriately leverage new
                external knowledge
              </Text>
            </Box>
          </Box>
        </Box>

        <Box as="li">
          <Text variant="ladderEyebrow">Prioritization & Execution</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Identifies how to break down problem spaces across pods with
                clear ownership and fair and appropriate allocation of
                engineering resources. Plans for and executes on the plan to
                grow the team across the pods with the right composition
              </Text>
            </Box>

            <Box as="li">
              <Text as="p" variant="body">
                Continually raises the bar for team members and takes advantage
                of opportunities for growth and development through delegation
                or stretch assignments. Addresses areas of underperformance. As
                a hiring manager, proactively recruits high potential candidates
              </Text>
            </Box>

            <Box as="li">
              <Text as="p" variant="body">
                Partners with product leadership and engineering leadership to
                create pod-level roadmaps. Keeps open communication to ensure
                that missions and strategies are feasible from a technical and
                resource constraint standpoint
              </Text>
            </Box>

            <Box as="li">
              <Text as="p" variant="body">
                Makes prudent trade-offs between long vs short term ROI and
                level of effort required
              </Text>
            </Box>
          </Box>
        </Box>

        <Box as="li">
          <Text variant="ladderEyebrow">Coaching & Development</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Effectively provides behavorial and technical feedback to peers
                & their teams. Tends to do this in more autonomous and proactive
                fashion than manager level
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Is able to support longer term career development of direct
                reports and skip levels. Ensures workstream assignments and long
                term development plans align with goals and abilities of team
                members
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Proactively flags tactical and strategic risks to projects to
                manager, along with plan(s) to resolve. Effectively surfaces
                concerns & questions from the team. Provides actionable insight
                into cross-team dynamics
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Supports strong performance management culture within broader
                function via feedback and participation in calibration sessions
              </Text>
            </Box>
          </Box>
        </Box>

        <Box as="li">
          <Text variant="ladderEyebrow">Leadership & Collaboration</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Partners with product, business, and function's engineering
                leadership re: longer term technical and organization plans for
                team
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Effectively represents technical perspective in cross-function
                discussions
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Effectively represents technical perspective in cross-function
                discussions; is meaningfully familiar with related workstreams
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>

      <Text as="p" variant="small">
        Since EM is L7, being promoted from L6 SSE, is an actual promotion with
        a new level
      </Text>
    </>
  )
}
