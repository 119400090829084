import * as React from "react"
import { FC } from "react"
import { Text, Box, Heading } from "theme-ui"

import { LevelHeading } from "../LevelHeading"

export const Level10IC: FC = () => {
  return (
    <>
      <LevelHeading>
        Senior Principal Software Engineer
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L10
        </Box>
      </LevelHeading>
      <Box sx={{ mb: [10, 12, null, 13, 14] }}>
        <Box as="ul" variant="layout.ladderList">
          <Box as="li">
            <Text variant="ladderEyebrow">
              Technical excellence (Generalist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Recognized expertise and judgement. Designs innovative,
                  game-changing approaches. Ability to lead significant
                  strategically, challenging or risky initiatives
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Strong understanding of how to structure communication that
                  is best suited for the audience - whether it's an engineer,
                  product, executive, or a customer
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Mitigate end to end system risks
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Understand problems that plague several teams and able to help
                  groups build the optimal solution considering trade offs
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Able to do trade-off analysis to understand the scaling,
                  reliability &amp; security concerns of introducing the other
                  technology at Better to solve a large class of problem
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Advanced understanding of security threats, proactively
                  identifies larger classes of attack vectors, and sets aside
                  time to protect ourselves
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text variant="ladderEyebrow">
              Technical excellence (Specialist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Recognized expertise and judgement. Designs innovative,
                  game-changing approaches. Ability to lead significant
                  strategically, challenging or risky initiatives
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Strong understanding of how to structure communication that
                  is best suited for the audience - whether it's an engineer,
                  product, executive, or a customer
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Mitigate end to end system risks
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Understand problems that plague several teams and able to help
                  groups build the optimal solution considering trade offs
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Influencing the future of a specific technology
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Advanced understanding of one specific industry technology
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text variant="ladderEyebrow">Initiative</Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Identifies and prevents global risk through best practices
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Setting squad standards, work with tech team across the
                  engineering organization to design consistent standards
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Partnering with peers and leading engineers, to build a suite
                  of services or technologies that significantly increases
                  multiple Squad's output
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text as="p" variant="ladderEyebrow">
              Influence (Generalist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Collaborates with Staff+ to influence the company's technical
                  decision making in the context of business objectives
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  In depth knowledge of the platform and multiple product
                  suites end to end. Working with senior leaders, contribute to
                  company strategy related to the relevant product suites.
                  Identify focus areas to close the gap between strategy and
                  development. Leading highly specialized expert teams working
                  in project capacity from time to time to close the gap, oversee
                  implementation from idealization to production. Smoothly
                  context switch from project to project, from team to team. Can
                  present larger initiatives to CEO &amp; exec level people.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Evaluate next generation technology trends and introduce the
                  applicable ones to improve the product and/or platform
                  capability. Working with engineering leadership, creating
                  standards and guidelines, enforcing best engineering practices
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Examples: Speaking at Conferences
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Identify and remove bureaucracy, provide performance review
                  recommendations to respective leadership for related teams when
                  working within a project capacity.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Help close on industry leaders and executives across the
                  company
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text as="p" variant="ladderEyebrow">
              Influence (Specialist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Trusted to have excellent technical judgement to the point where 
                  they have full autonomy to spend their time on whatever they 
                  think is most important for the business (scoped to multiple 
                  Squads)
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  In depth knowledge of the platform and multiple product
                  suites end to end. Working with senior leaders, contribute to
                  company strategy related to the relevant product suites.
                  Identify focus areas to close the gap between strategy and
                  development. Leading highly specialized expert teams working
                  in project capacity from time to time to close the gap, oversee
                  implementation from idealization to production. Smoothly
                  context switch from project to project, from team to team. Can
                  present larger initiatives to CEO &amp; exec level people.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Evaluate next generation technology trends and introduce the
                  applicable ones to improve the product and/or platform
                  capability. Working with engineering leadership, creating
                  standards and guidelines, enforcing best engineering practices
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Examples: Speaking at Conferences
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Identify and remove bureaucracy, provide performance review
                  recommendation to respective leadership for related teams when
                  working within a project capacity.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Help close on industry leaders and executives across the
                  company
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
