import * as React from "react"
import { FC } from "react"
import { Text, Box, Heading } from "theme-ui"

import { LevelHeading } from "../LevelHeading"

export const Level10Manager: FC = () => {
  return (
    <>
      <LevelHeading>
        Senior Director, Engineering
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L10
        </Box>
      </LevelHeading>
      <Box as="ul" variant="layout.ladderList">
        <Box as="li">
          <Heading variant="h5">Typical Experience</Heading>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                At least 2 years as a Senior Engineering Manager (L8) at
                Better.com or 4 years as Director of Engineering elsewhere, if
                hired directly into the role or combined with Better.com
                experience
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                When promoted to Director of Engineering, a Senior Engineering
                Manager should have mentored and trained several Senior Software
                Engineers to become Engineering Managers
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Promoted to Director role after showing constant and reliable
                performance as a Senior Engineering Manager for several
                consecutive cycles
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Heading variant="h5">Scope & Impact</Heading>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Manager of managers, no direct IC reports except L8/L9 Principal
                Software Engineers
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Leads multiple engineering teams, totaling 30-50 engineers, and
                Engineering Managers, Senior Engineering Managers and,
                optionally, other Directors of Engineering
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Manages teams/pods of one or multiple squads
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Negotiates employee salaries, team budgets etc
              </Text>
            </Box>
          </Box>
        </Box>

        <Box as="li">
          <Heading variant="h4" sx={{ mb: [7, 8, 7, 8, 9] }}>
            Competencies
          </Heading>
          <Text variant="ladderEyebrow">Technical Excellence</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Anticipates the impact of architectural changes across technical
                areas & actively influences these changes. Understands how the
                systems and products within their domain affect the product as a
                whole and applies this to their regular decision making
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Communicates clearly and effectively across teams and
                departments, adjusting their approach to fit the levels and
                communication styles of their audience. Regularly engages with
                C-level
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Understands the business processes and market opportunities of
                Better as a whole, and partners with product and business
                stakeholders to design technical solutions
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Is responsible for ensuring that their organization has
                appropriate access to industry knowledge, whether through
                training, conferences, networking, and / or experienced external
                hiring
              </Text>
            </Box>
          </Box>
        </Box>

        <Box as="li">
          <Text variant="ladderEyebrow">Prioritization & Execution</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Identify & enact ideal organizational structure to maximize
                productivity and minimize overlapping initiatives
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Positively influences the engineering cultureacross Engineering
                broadly, and ensures engineering growth and learning
                opportunities are ever-present. Broadly supports high standards
                in hiring within Engineering
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Is responsible influencing broader product and business vision
                within area. Owns building mission and strategy level roadmaps
                with Product Leadership that has cross-functional buy-in. Is
                responsible for making sure this work stays on track, or
                adjusting/pivoting as necessary
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Works cross-functionally to create net-new opportunities for
                innovation and value in a self-driven fashion
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Text variant="ladderEyebrow">Coaching & Development</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Establishes broader network with Bettter to build trusting
                partnerships that lead to improved outcomes for Better outside
                of immediate remit
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Effectively coaches and develops team members into high
                performing directors
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Proactively partners with manager to identify and resolve long
                term strategic risks to Better more broadly
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Creates performance-based culture within functional area.
                Ensures level of execution continually increasing
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Text variant="ladderEyebrow">Leadership & Collaboration</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Constructively influences cross-functional partners to carve
                space for multi-quarter execution and value-add around the North
                Star vision
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Leads major cross-functional projects & generates buy-in for
                them
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Drives strategy for functional area and is able to apply
                multiple years of focused experience in support of this
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
