import * as React from "react"
import { FC } from "react"
import { Text, Box } from "theme-ui"

import { LevelHeading } from "../LevelHeading"

export const Level3IC: FC = () => {
  return (
    <>
      <LevelHeading>
        Software Engineer
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L3
        </Box>
      </LevelHeading>
      <Box as="ul" variant="layout.ladderList">
        <Box as="li">
          <Text variant="ladderEyebrow">Technical excellence</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Is able to work on tasks independently for a few weeks or up to
                a month
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Works effectively with other engineers to build out a working
                feature
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Is able to quickly unblock themselves to understand the task
                assigned to them
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                CS fundamentals &amp; adheres to engineering best practices
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Text variant="ladderEyebrow">Initiative</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Field public questions to help triage issues
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Strives to ensure own code is high quality by soliciting
                review/feedback, resolving comments and ensuring appropriate
                unit test coverage
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Identifies potential improvements in personal efficiency
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Text variant="ladderEyebrow">Influence</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Builds stable relationships with other coworkers. Understands
                basic use case of team's product (or domain area)
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Participates in Jira conversations, standups, or other team
                meetings
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
