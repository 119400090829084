import * as React from "react"
import { FC } from "react"
import { useSpring, config, animated } from "react-spring"
import { Box, Button } from "theme-ui"

export interface IPropsToggle {
  onClick: () => void
  isManager: boolean
}

const AnimatedBox = animated(Box)

export const Toggle: FC<IPropsToggle> = ({ isManager, onClick }) => {
  const { x } = useSpring({
    x: isManager ? 100 : 0,
    from: {
      x: 0,
    },
    config: { mass: 1, tension: 200, friction: 20 },
  })

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <ButtonLabel onClick={onClick} isChecked={!isManager}>
        Technical
      </ButtonLabel>
      <Button
        sx={{
          px: "5px",
          mx: 3,
          display: "inline-flex",
          cursor: "pointer",
          outline: "none",
          bg: "backgroundSecondary",
          color: "text",
          borderRadius: "9999px",
          height: 36,
          width: 62,
          flexShrink: 0,
          alignItems: "center",

          ":focus-visible": {
            outline: "1px dashed",
          },

          ":active": {
            boxShadow: "0 0 0 3px hsla(0, 0%, 12%, 0.15)",
          },
        }}
        onClick={onClick}
      >
        <AnimatedBox
          style={{
            transform: x.to((value: number) => `translateX(${value}%)`),
          }}
        >
          <AnimatedBox
            sx={{
              height: 26,
              width: 26,
              bg: "textWhite",
              borderRadius: "9999px",
              transition: "transform 0.25s var(--animation-bezier)",

              "button:hover &": {
                transitionDuration: "0.125s",
                transform: "scale(1.1)",
              },
            }}
          />
        </AnimatedBox>
      </Button>
      <ButtonLabel onClick={onClick} isChecked={isManager}>
        Manager
      </ButtonLabel>
    </Box>
  )
}

interface IPropsButtonLabel {
  children: string
  isChecked: boolean
  onClick: (value: boolean) => void
}

const ButtonLabel: FC<IPropsButtonLabel> = ({
  children,
  isChecked,
  onClick,
}) => {
  return (
    <Button
      sx={{
        cursor: "pointer",
        appearance: "none",
        bg: "transparent",
        outline: "none",
        color: "currentcolor",
        font: "inherit",
        letterSpacing: "-0.04em",
        opacity: isChecked ? 1 : 0.5,
        p: 0,

        ":hover": {
          opacity: 1,
        },

        ":focus-visible": {
          outline: "1px dashed",
        },
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
