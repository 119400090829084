import * as React from "react"
import { FC } from "react"
import { Text, Box, Heading } from "theme-ui"

import { LevelHeading } from "../LevelHeading"

export const Level11Manager: FC = () => {
  return (
    <>
      <LevelHeading>
        VP of Engineering
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L11
        </Box>
      </LevelHeading>
      <Box as="ul" variant="layout.ladderList">
        <Box as="li">
          <Heading variant="h5">Typical Experience</Heading>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                At least 2 years of experience as Director of Engineering at
                Better.com or 4 years of experience as Director of Engineering
                or similar elsewhere if hired into the role (or combined with
                Better.com experience)
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Heading variant="h5">Scope & Impact</Heading>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Leads multiple teams/squads totaling to 50+ engineers
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Manages 7-8 Engineering and Senior Engineering Managers and
                Directors of Engineering
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Heading variant="h4" sx={{ mb: [7, 8, 7, 8, 9] }}>
            Competencies
          </Heading>
          <Text variant="ladderEyebrow">Technical Excellence</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Understands the architecture of the systems in their domain, the
                needs of the product and business, and the steps required
                connect the two. Understands how those systems inteconnect to
                the larger organization and business, and makes data-driven
                strategic decisions accordingly
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Communicates clearly and effectively across department.
                Successfully engages/partners with other teams/leaders across
                the organization and encourages direct reports to do the same
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Proactively identifies problems & opportunities that can impact
                the organization before they come to pass so that their team is
                able to with high agility. They demonstrate and share a clear
                understanding of how they system as a whole is affected by
                ongoing business decisions
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Owns team's holistic approach to industry trends, relevant
                tools/languages/technologies, and engineer training to ensure we
                are skillset growth and technical/business needs. Is responsible
                that Better is, where appropriate, engaging externally with the
                industry
              </Text>
            </Box>
          </Box>
        </Box>

        <Box as="li">
          <Text variant="ladderEyebrow">Prioritization & Execution</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Focusing on a larger time horizon. VP should identify & act on
                new and/or modified organizations to drive new lines of
                businesses for Better, as well as influence the areas for
                additional investments
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Owns headcount-related risk and succession planning within their
                org. Forecasts and builds headcount and recruiting plans to
                ensure we are staffed with the right people and skills to
                execute on today's work and adapt to future challenges. Fosters
                a culture of learning and growth to attract, motivate, and keep
                top talent
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Owns the larger vision of the products and services within their
                domain, and how those products interact with the current and
                future state of the rest of the roadmap. Responsible for direct
                reports building out the detailed short/mid-term roadmap while
                they focus on the larger vision and strategic direction of the
                product over the next few quarters to years
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Ensures entire domain is creating meaningful ROI at the Better
                company level and is balancing short term delivery with long
                term innovation
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Text variant="ladderEyebrow">Coaching & Development</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Constructively influences cross-functional organizational design
                and/or strategy via feedback and building trust
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Is responsible for maintaining high quality coaching &
                development across function and/or business unit. Supports it
                as key part of team culture
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Provides peer-level feedback for manager as appropriate
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Constructively supports improving performance bar across
                function and cross-functional partners
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Text variant="ladderEyebrow">Leadership & Collaboration</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Owns multi-year strategy for either a horizontal function or a
                verticalized engineering team
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Identifies long term needs to support Better and creates
                momentum behind net-new and highly complex cross-functional
                projects
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Influences company's direction using specialized knowledge &
                experience
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
