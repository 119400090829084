import * as React from "react"
import { FC } from "react"
import { Text, Box } from "theme-ui"

import { LevelHeading } from "../LevelHeading"

export const Level4IC: FC = () => {
  return (
    <>
      <LevelHeading>
        Software Engineer
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L4
        </Box>
      </LevelHeading>
      <Box as="ul" variant="layout.ladderList">
        <Box as="li">
          <Text variant="ladderEyebrow">Technical excellence</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Shows a grasp of the basic style guides and design patterns
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Works autonomously on projects up to a week or two in scope with
                minor supervision
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Is able to quickly unblock themselves and complete groups of
                tasks assign to them
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Writes code that's reasonably readable and easy for other people
                to follow. Consistently exhibits good engineering practices
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Gives clear updates on how a project is going and when to expect
                it to be finished
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Debugs basic things in production using developer console and
                similar basic tooling
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Understands team projects well
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Starts to identify how features relate to security threats
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Text variant="ladderEyebrow">Initiative</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Activly participant in on-call rotations, helps triage issues,
                starts to tackle and debug technical incidents
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Participates in code-reviews, actively commenting on PRs
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Identifies points of friction within engineering processes
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Text variant="ladderEyebrow">
            Influence
          </Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Partners with product on understanding concrete product
                requirements and translating them to working features and/or
                software. Understands part of team's domain
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Constructive pull request reviews, occasionally points out
                issues to their team members
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Participates in Jira conversations, standups, or other team
                meetings
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
