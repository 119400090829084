import * as React from "react"
import { FC } from "react"
import { Grid, SxStyleProp, Text, Heading } from "theme-ui"

import { Level3IC } from "../Level3IC"
import { Level4IC } from "../Level4IC"
import { Level5IC } from "../Level5IC"
import { Level6IC } from "../Level6IC"
import { Level7IC } from "../Level7IC"
import { Level8IC } from "../Level8IC"
import { Level9IC } from "../Level9IC"
import { Level10IC } from "../Level10IC"
import { Level11IC } from "../Level11IC"
import { Level7Manager } from "../Level7Manager"
import { Level8Manager } from "../Level8Manager"
import { Level9Manager } from "../Level9Manager"
import { Level10Manager } from "../Level10Manager"
import { Level11Manager } from "../Level11Manager"
import { Level12Manager } from "../Level12Manager"
import { IPropsLevel } from "../ToggleableSummary"
import { Panel } from "../Panel"

export interface IPropsLadderContent {
  isManager: boolean
  selected: IPropsLevel
  setPanelHeight: (value: number) => void
  sx: SxStyleProp
}

export const LadderContent: FC<IPropsLadderContent> = ({
  isManager,
  selected,
  setPanelHeight,
  sx,
}) => {
  return (
    <Grid
      variant="base"
      sx={{ alignItems: "flex-start", overflow: "hidden", ...sx }}
    >
      <Panel
        isSelected={!isManager && selected === "L3"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level3IC />
      </Panel>
      <Panel
        isSelected={!isManager && selected === "L4"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level4IC />
      </Panel>
      <Panel
        isSelected={!isManager && selected === "L5"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level5IC />
      </Panel>
      <Panel
        isSelected={!isManager && selected === "L6"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level6IC />
      </Panel>
      <Panel
        isSelected={!isManager && selected === "L7"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level7IC />
      </Panel>
      <Panel
        isSelected={!isManager && selected === "L8"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level8IC />
      </Panel>
      <Panel
        isSelected={!isManager && selected === "L9"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level9IC />
      </Panel>
      <Panel
        isSelected={!isManager && selected === "L10"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level10IC />
      </Panel>
      <Panel
        isSelected={!isManager && selected === "L11"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level11IC />
      </Panel>
      <Panel
        isSelected={!isManager && selected === "L12"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Heading variant="h3" as="h3" sx={{ fontWeight: 300, mb: 3 }}>
          L12
        </Heading>
        <Text variant="body" sx={{ mb: [0, 0, 0, 0, 0] }}>
          At this level, there is no technical counterpart.
        </Text>
      </Panel>
      <Panel
        isSelected={isManager && selected === "L3"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Heading variant="h3" as="h3" sx={{ fontWeight: 300, mb: 3 }}>
          L3
        </Heading>
        <Text variant="body" sx={{ mb: [0, 0, 0, 0, 0] }}>
          At this level, there is no management counterpart.
        </Text>
      </Panel>
      <Panel
        isSelected={isManager && selected === "L4"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Heading variant="h3" as="h3" sx={{ fontWeight: 300, mb: 3 }}>
          L4
        </Heading>
        <Text variant="body" sx={{ mb: [0, 0, 0, 0, 0] }}>
          At this level, there is no management counterpart.
        </Text>
      </Panel>
      <Panel
        isSelected={isManager && selected === "L5"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Heading variant="h3" as="h3" sx={{ fontWeight: 300, mb: 3 }}>
          L5
        </Heading>
        <Text variant="body" sx={{ mb: [0, 0, 0, 0, 0] }}>
          At this level, there is no management counterpart.
        </Text>
      </Panel>
      <Panel
        isSelected={isManager && selected === "L6"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Heading variant="h3" as="h3" sx={{ fontWeight: 300, mb: 3 }}>
          L6
        </Heading>
        <Text variant="body" sx={{ mb: [0, 0, 0, 0, 0] }}>
          At this level, there is no management counterpart.
        </Text>
      </Panel>
      <Panel
        isSelected={isManager && selected === "L7"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level7Manager />
      </Panel>
      <Panel
        isSelected={isManager && selected === "L8"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level8Manager />
      </Panel>
      <Panel
        isSelected={isManager && selected === "L9"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level9Manager />
      </Panel>
      <Panel
        isSelected={isManager && selected === "L10"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level10Manager />
      </Panel>
      <Panel
        isSelected={isManager && selected === "L11"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level11Manager />
      </Panel>
      <Panel
        isSelected={isManager && selected === "L12"}
        setPanelHeight={setPanelHeight}
        sx={sx}
      >
        <Level12Manager />
      </Panel>
    </Grid>
  )
}
