/** @jsx jsx */
import { FC, useEffect } from "react"
import { animated, useSpring, useTransition } from "react-spring"
import { useMeasure, useMedia } from "react-use"
import { jsx, Box, SxStyleProp } from "theme-ui"

import { Cube } from "../Cube"

const AnimatedBox = animated(Box)

export type IPropsLevel =
  | "L3"
  | "L4"
  | "L5"
  | "L6"
  | "L7"
  | "L8"
  | "L9"
  | "L10"
  | "L11"
  | null

export interface IPropsCubeConfig {
  career: "manager" | "technical"
  index: number
  level: IPropsLevel
  managerRole?: string
  positionX: number
  positionY: number
  role: string
  rotationX: number
}

export interface IPropsToggleableSummary {
  isManager: boolean
  selected: IPropsLevel
  setSummaryHeight: (value: number) => void
  setIsManager: (value: boolean) => void
  setSelected: (level: IPropsLevel) => void
  sx: SxStyleProp
}

export const ToggleableSummary: FC<IPropsToggleableSummary> = ({
  isManager,
  selected,
  sx,
  setIsManager,
  setSelected,
  setSummaryHeight,
}) => {
  const isDesktop = useMedia("(min-width: 970px)")
  const [sizeRef, { width: containerWidth, height }] = useMeasure()

  const items: IPropsCubeConfig[] = [
    {
      index: 0,
      career: "technical",
      positionY: 9,
      positionX: 0,
      rotationX: 0,
      level: "L3",
      role: "Software Engineer",
    },
    {
      index: 1,
      career: "technical",
      positionY: 8,
      positionX: 1,
      rotationX: 0,
      level: "L4",
      role: "Software Engineer",
    },
    {
      index: 2,
      career: "technical",
      positionY: 7,
      positionX: 2,
      rotationX: 0,
      level: "L5",
      role: "Software Engineer",
    },
    {
      index: 3,
      career: "technical",
      positionY: 6,
      positionX: 3,
      rotationX: 0,
      level: "L6",
      role: "Senior Software Engineer",
    },
    {
      index: 4,
      career: isManager ? "manager" : "technical",
      positionY: 5,
      positionX: 4,
      rotationX: 0,
      level: "L7",
      role: "Staff Software Engineer",
      managerRole: "Engineering Manager",
    },
    {
      index: 5,
      career: isManager ? "manager" : "technical",
      positionY: 4,
      positionX: 5,
      rotationX: 0,
      level: "L8",
      role: "Senior Staff Software Engineer",
      managerRole: "Senior Engineering Manager",
    },
    {
      index: 6,
      career: isManager ? "manager" : "technical",
      positionY: 3,
      positionX: 6,
      rotationX: 0,
      level: "L9",
      role: "Principal Engineer",
      managerRole: "Director, Engineering",
    },
    {
      index: 7,
      career: isManager ? "manager" : "technical",
      positionY: 2,
      positionX: 7,
      rotationX: 0,
      level: "L10",
      role: "Senior Principal Engineer",
      managerRole: "Senior Director, Engineering",
    },
    {
      index: 8,
      career: isManager ? "manager" : "technical",
      positionY: 1,
      positionX: 8,
      rotationX: 0,
      level: "L11",
      role: "Distinguished Engineer",
      managerRole: "VP of Engineering",
    },
  ]

  const cubeLenght = 6

  const cubeSize = containerWidth / cubeLenght
  const stepY = cubeSize / 4

  // scale down the cube at smaller breakpoints
  const reducedScale = isDesktop ? 0.25 : 0.6

  let gridItems = items.map((child) => {
    const { index, positionX, positionY, rotationX, career, level } = child
    let y, x, z, scale, rotationY

    const toggledPositionX = isManager ? positionX - 4 : positionX
    const toggledPositionY = isManager ? positionY : positionY - 4

    if (selected === null) {
      scale = 0.95
      y = stepY * toggledPositionY
      x = cubeSize * toggledPositionX
      z = cubeSize / 2
      rotationY = 0
    } else {
      const margin = 0.025

      // move boxes to the right on mobile
      const xClosedPosition = isDesktop ? -0.25 : 5

      const invertedIndex = items.length - index - 6 - (isDesktop ? 0 : -1)

      scale = (1 - margin * 2) * reducedScale
      const marginSize = cubeSize * margin * scale
      x = (marginSize + cubeSize + marginSize) * xClosedPosition
      y =
        (marginSize + cubeSize + marginSize) * reducedScale * invertedIndex -
        marginSize * 8
      z = (marginSize + cubeSize + marginSize) * (reducedScale / 2)
      rotationY = selected === level ? -180 : -90
    }

    return {
      ...child,
      transform: [x, y, z, scale, rotationY, rotationX],
      width: cubeSize,
    }
  })

  const transition = useTransition(gridItems, {
    key: (item) => item.index,
    from: ({ transform }) => ({ transform }),
    enter: ({ transform }) => ({ transform }),
    update: ({ transform }) => ({ transform }),
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 25,
  })

  const { perspectiveX } = useSpring({
    perspectiveX: selected === null ? 0 : 50,
    from: { perspectiveX: 0 },
    config: {
      mass: 5,
      tension: 500,
      friction: 100,
      duration: 500,
    },
  })

  const toggleSelected = (level: IPropsLevel) => {
    switch (level) {
      case "L3":
      case "L4":
      case "L5":
      case "L6":
        setIsManager(false)
        break
    }
    if (level === selected) {
      setSelected(null)
    } else {
      setSelected(level)
    }
  }

  let summaryHeight: number

  if (selected === null) {
    // height of closed summary = cubeSize * 2.25
    summaryHeight = cubeSize + stepY * (cubeLenght - 1)
  } else {
    summaryHeight = cubeSize * reducedScale * cubeLenght
  }

  useEffect(() => {
    setSummaryHeight(summaryHeight)
  }, [isManager, selected, cubeSize])

  return (
    <AnimatedBox
      ref={sizeRef}
      sx={{
        display: "grid",
        perspective: 4000,
        height: summaryHeight,
        pointerEvents: "none",
        ...sx,
      }}
      style={{
        perspectiveOrigin: perspectiveX.to(
          (value: number) => `${value}% bottom`
        ),
      }}
    >
      {transition((animationProps, item) => {
        return (
          <Cube
            animationProps={animationProps}
            isManager={isManager}
            item={item}
            onClick={() => toggleSelected(item.level)}
            selected={selected}
            width={cubeSize}
          />
        )
      })}
    </AnimatedBox>
  )
}
