import * as React from "react"
import { FC } from "react"
import { Text, Box, Heading } from "theme-ui"

import { LevelHeading } from "../LevelHeading"

export const Level12Manager: FC = () => {
  return (
    <>
      <LevelHeading>
        SVP of Engineering
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L12
        </Box>
      </LevelHeading>
      <Box as="ul" variant="layout.ladderList">
        <Box as="li">
          <Heading variant="h5">Typical Experience</Heading>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                At least 5 years of experience managing an engineering team of
                300+ engineers
              </Text>
              <Text as="p" variant="body">
                Proven ability to successfully take over a team of 150+
                engineers and scale to 300+
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Heading variant="h5">Scope & Impact</Heading>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Oversees an organization of 200+ engineers
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Manages directly several Directors of Engineering and VP of
                Engineering
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
