import * as React from "react"
import { FC } from "react"
import { Text, Box, Heading } from "theme-ui"

import { LevelHeading } from "../LevelHeading"

export const Level11IC: FC = () => {
  return (
    <>
      <LevelHeading>
        Distinguished Software Engineer
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L11
        </Box>
      </LevelHeading>
      <Box sx={{ mb: [10, 12, null, 13, 14] }}>
        <Box as="ul" variant="layout.ladderList">
          <Box as="li">
            <Text variant="ladderEyebrow">
              Technical excellence (Generalist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Recognized expertise and high judgement. Designs innovative,
                  company-changing approaches that deliver value to an industry.
                  Ability to lead our largest challenging, strategically
                  important and risky initiatives.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Strong understanding of how to structure communication that
                  is best suited for the audience - whether it's an engineer,
                  product, executive, or a customer
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Mitigate company strategic risks
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Identify problems that will plague the organization before
                  they come to pass so we can avoid them altogether
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Able to do trade-off analysis to understand the scaling,
                  reliability &amp; security concerns of introducing other
                  technologies at Better to solve a large class of problems
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Advanced understanding of security threats, proactively
                  identifies larger classes of attack vectors, and sets aside
                  time to protect ourselves
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text variant="ladderEyebrow">
              Technical excellence (Specialist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Recognized expertise and high judgement. Designs innovative,
                  company-changing approaches that deliver value to an industry.
                  Ability to lead our largest challenging, strategically
                  important and risky initiatives.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Strong understanding of how to structure communication that
                  is best suited for the audience - whether it's an engineer,
                  product, executive, or a customer
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Mitigate company strategic risks
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Identify problems that will plague the organization before
                  they come to pass so we can avoid them altogether
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Renowned as a leader driving significant change in a specific
                  technology
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Advanced understanding of one specific industry technology
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text variant="ladderEyebrow">Initiative (Generalist)</Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Identifies and prevents global risk through best practices
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Setting company standards
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Work with the executive team to build out the Better brand 
                  as a industry leading tech company
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text variant="ladderEyebrow">Initiative (Specialist)</Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Identifies and prevents global risk through best practices
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Setting company standards
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  An industry expert in a particular field
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text as="p" variant="ladderEyebrow">
              Influence (Generalist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Collaborates with other industry leaders to influence 
                  broad technical decision making
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Respected by senior leadership due to in depth knowledge and 
                  track record on delivering key strategic projects across the 
                  entire product offering by the company. Regarded as the go to 
                  leader on everything related to technology choice, platform 
                  evolution, company tech strategy formation and review. Can 
                  present larger initiatives to CEO and exec level people at any 
                  time.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Intimate knowledge and always on the lookout for the technical 
                  choices made by competitors and tools providers. Leading tech 
                  due diligence on M&amp;A targets and/or vendor selections. 
                  Trusted partner to CTO on tech platform choices and directions. 
                  Making recommendations to company execs on strategic tech 
                  partnership evaluations.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Examples: Speaking at Conferences, evangelizing Better engineering 
                  brand
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Identify and remove bureaucracy, provide performance review
                  recommendation to senior leadership for related teams when
                  working within a project capacity.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Names of leaders in this group should carry enough weight here 
                  to attract talent.
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text as="p" variant="ladderEyebrow">
              Influence (Specialist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Trusted to have excellent technical judgement to the point where 
                  they have full autonomy to spend their time on whatever they think 
                  is most important for the business (scoped to the company)
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Respected by senior leadership due to in depth knowledge and track 
                  record on delivering key strategic projects across the entire product 
                  offering by the company. Regarded as the go to leader on everything 
                  related to technology choice, platform evolution, company tech strategy 
                  formation and review. Can present larger initiatives to CEO and exec 
                  level people at any time.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Intimate knowledge and always on the lookout for the technical choices 
                  made by competitors and tools providers. Leading tech due diligence on 
                  M&amp;A targets and/or vendor selections. Trusted partner to CTO on tech 
                  platform choices and directions. Making recommendations to company execs 
                  on strategic tech partnership evaluations.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Examples: Speaking at Conferences, evangelizing Better engineering brand
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Identify and remove bureaucracy, provide performance review
                  recommendation to senior leadership for related teams when
                  working within a project capacity.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Names of leaders in this groups should carry enough weight here to attract talent.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
