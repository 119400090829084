import * as React from "react"
import { FC } from "react"
import { Text, Box, Heading } from "theme-ui"

import { LevelHeading } from "../LevelHeading"

export const Level9Manager: FC = () => {
  return (
    <>
      <LevelHeading>
        Director, Engineering
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L9
        </Box>
      </LevelHeading>
      <Box as="ul" variant="layout.ladderList">
        <Box as="li">
          <Heading variant="h4">Typical Experience</Heading>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                At least 2 years as a Senior Engineering Manager (L8) at
                Better.com or 4 years as Director of Engineering elsewhere, if
                hired directly into the role or combined with Better.com
                experience
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                When promoted to Director of Engineering, a Senior Engineering
                Manager should have mentored and trained several Senior Software
                Engineers to become Engineering Managers
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Promoted to Director role after showing constant and reliable
                performance as a Senior Engineering Manager for several
                consecutive cycles
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Heading variant="h4">Scope & Impact</Heading>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Manager of managers, no direct IC reports except L8/L9 Principal
                Software Engineers
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Leads multiple engineering teams, totaling 30-50 engineers, and
                Engineering Managers, Senior Engineering Managers and,
                optionally, other Directors of Engineering
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Manages teams/pods of one or multiple squads
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Negotiates employee salaries, team budgets etc
              </Text>
            </Box>
          </Box>
        </Box>

        <Box as="li">
          <Heading variant="h4" sx={{ mb: [7, 8, 7, 8, 9] }}>
            Competencies
          </Heading>
          <Text variant="ladderEyebrow">Technical Excellence</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Anticipates the impact of architectural changes across technical
                areas and makes intelligent engineering tradeoffs. Understands
                how the systems and products within their domain affect the
                product as a whole and applies this to their regular decision
                making
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Communicates clearly and effectively across teams and
                departments, adjusting their approach to fit the levels and
                communication styles of their audience
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Understands the business processes and market opportunities of
                the area of Better their org supports, and partners with product
                and business stakeholders to design technical solutions
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Ensures a proper balance between 'new' and 'useful', as well as
                'build' vs. 'buy'. Is accountable to ensure that their teams to
                keep their skills current as appropriate
              </Text>
            </Box>
          </Box>
        </Box>

        <Box as="li">
          <Text variant="ladderEyebrow">Prioritization & Execution</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Manage scope and resources for their full organization to ensure
                pods can execute and deliver business value as autonomously as
                possible. Plans ahead to ensure the organizational design will
                scale and evolve as needed over time
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Positively influences the engineering culture within their and
                adjacent teams, and ensures engineering growth and learning
                opportunities are ever-present. Supports high standards in
                hiring within their and adjacent organizations
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Is responsible for balancing long and short term deliverables
                alongside product and business partners. Owns building mission
                and strategy level roadmaps. Is responsible for making sure this
                work stays on track, or adjusting/pivoting as necessary
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Effectively manages long term portfolio of projects to create
                value for Better
              </Text>
            </Box>
          </Box>
        </Box>

        <Box as="li">
          <Text variant="ladderEyebrow">Coaching & Development</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Works closely with peers who lead cross-functional organizations
                to build trusting partnerships that lead to improved outcomes
                for Better
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Effectively coaches and develops team members into high
                performing managers and senior managers. Helps to drive highly
                effective calibration and review processes for team
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Proactively partners with manager to identify and resolve long
                term strategic risks to immediate domain
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Is an advocate for impact-based performance culture within
                function and in Better, more broadly. Drives high quality
                calibration and review sessions within team
              </Text>
            </Box>
          </Box>
        </Box>

        <Box as="li">
          <Text variant="ladderEyebrow">Leadership & Collaboration</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Is responsible for developing a North Star vision and
                reconciling progress towards the North Star with quarterly
                progress
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Engages and helps to influence strategic cross-functional
                discussions, e.g. squad and multi-squad roadmap creation
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Drives strategy for functional area and all related workstreams
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
