import * as React from "react"
import { FC, ReactNode } from "react"
import { Heading } from "theme-ui"

export interface IPropsLevelHeading {
  children: ReactNode
}

export const LevelHeading: FC<IPropsLevelHeading> = ({ children }) => {
  return (
    <Heading as="h3" variant="h3" sx={{ mb: [6, 7, 5, 9, 10] }}>
      {children}
    </Heading>
  )
}
