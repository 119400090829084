import * as React from "react"
import { FC } from "react"
import { Text, Box, Heading } from "theme-ui"

import { LevelHeading } from "../LevelHeading"
import { Arrow } from "components/Arrow"

export const Level6IC: FC = () => {
  return (
    <>
      <LevelHeading>
        Senior Software Engineer
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L6
        </Box>
      </LevelHeading>
      <Box sx={{ mb: [10, 12, null, 13, 14] }}>
        <Box as="ul" variant="layout.ladderList">
          <Box as="li">
            <Text variant="ladderEyebrow">Technical excellence</Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Trusted to set aside time here and there for whatever
                  technical improvements they believe in
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Leads a larger initiative for maybe a month or two ie: factor
                  out a piece of code into a separate service
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Breaks their work into smaller deliverables, considers how
                  tasks can be effectively distributed within the team
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Strong opinions and knowledge about the current technology
                  landscape, argues for/against various architectural decisions
                  impacting projects of various sizes
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Generally unambiguous communication that's to the point,
                  assumes good intentions
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Takes ownership of postmortem follow up items, proactively
                  ensures that a high SEV issue doesn't repeat
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Understands team business and product domain well, is building
                  mastery in another domain, for example, infrastructure (AWS,
                  Kubernetes, Plutus), platform (Tinman, RBAC, Authentication),
                  and business (Mortgage, Real Estate, etc.)
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Generally up to date on the current technology landscape and
                  trends, starts conversations around areas we could take
                  advantage of
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Solid understanding of security threats and uses this
                  knowledge to proactively point out security issues in pull
                  requests or similar
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text variant="ladderEyebrow">Initiative</Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Frequent contributor to and jumps onto preventing/mitigating
                  outages often! Not a passive bystander
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Driving conversations about technical decisions, leading
                  change to one or more standards within a team, creating
                  influence through PR reviews
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Shows initiative and independently execute process
                  improvements for the Pod
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text variant="ladderEyebrow">Influence</Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Articulates problem statements, sizes opportunities, and
                  brings substantive ideas to leadership on how to improve the
                  organization/product direction
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Trusted to interact with &amp; represent the team with other
                  stakeholders/PMs to drive decisions
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Responsible for coordinating technical projects that involve
                  other Pods or Subject Matter Experts
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Create content that requires coordination with other
                  stakeholders or is recognized within the tech world
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Unblocking other members on the same team to be more effective
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Participates in recruiting – helps out with interviewing.
                  Always gets feedback into the system quickly
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Heading variant="h3" sx={{ mb: [6, 7, 5, 9, 10] }}>
        Path to Engineering Manager
      </Heading>
      <Heading variant="h4">
        Senior Software Engineer <Arrow /> Engineering Manager
      </Heading>
      <Box as="ul" variant="layout.ladderListInner">
        <Box as="li">
          <Text as="p" variant="body">
            Senior Software Engineers L6 and higher are eligible to start
            progression into the management track
          </Text>
        </Box>
        <Box as="li">
          <Text as="p" variant="body">
            Either indicated interest or given direction by a manager -
            director/senior manager creates a 6 month plan and identifies areas
            to focus on
          </Text>
        </Box>
        <Box as="li">
          <Text as="p" variant="body">
            As part of the mentoring influence, should be assigned 1 to 2 L3-L5
            engineers to mentor and as direct reports - ideally new team members
          </Text>
        </Box>
        <Box as="li">
          <Text as="p" variant="body">
            During the 6 months course, the engineer should become an effective
            tech lead in the current pod, assisting PMs with technical product
            management
          </Text>
        </Box>
        <Box as="li">
          <Text as="p" variant="body">
            Over the course of the 6 months, decisions between the engineer and
            director/senior manager will be made whether to continue on the
            Engineering Track or transition to Manager Track, together with
            title change
          </Text>
        </Box>
        <Box as="li">
          <Text as="p" variant="body">
            Candidates that were previously Engineering Managers/Tech Leads with
            1-5 direct reports and with less than 4 years of management
            experience, should follow this track as well: hired as L6 Senior
            Software Engineers and provided a plan to move into the management
            track over their first 6 months
          </Text>
        </Box>
        <Box as="li">
          <Text as="p" variant="body">
            As Engineering Manager, Senior Software Engineers moving into this
            track and getting assigned L7 level, there might be a compensation
            change required to fit into the right bracket. It is important to
            try to move into the Management track while in L6/L7 to avoid this
          </Text>
        </Box>
      </Box>
    </>
  )
}
