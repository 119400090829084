import * as React from "react"
import { FC } from "react"
import { animated, SpringValue } from "react-spring"
import { Box, Button, SxStyleProp, Heading } from "theme-ui"

import { Face } from "./Face"
import { IPropsCubeConfig, IPropsLevel } from "../ToggleableSummary"

export interface IPropsCube {
  animationProps: {
    transform: SpringValue<number[]>
  }
  className?: string
  isManager: boolean
  item: IPropsCubeConfig
  onClick: () => void
  selected: IPropsLevel
  width: number
}

const AnimatedButton = animated(Button)

export const Cube: FC<IPropsCube> = ({
  animationProps: { transform },
  className,
  isManager,
  item: { role, level, managerRole, positionY, career },
  onClick,
  selected,
  width,
}) => {
  const isSelected = selected === level

  const faceStyle: SxStyleProp = {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    bg: "var(--color-bg)",
  }

  const colorCarbon = "rgb(41 41 41)"
  const colorActive = "#4b815c"

  const isActiveCareer =
    (isManager && career === "manager") ||
    (!isManager && career === "technical")

  return (
    <AnimatedButton
      className={className}
      onClick={onClick}
      style={{
        transform: transform.to(
          (x, y, z, scale, rotationY, rotationX) =>
            `translate3d(${x}px, ${y}px, ${z}px) scale(${scale}) rotateY(${rotationY}deg) rotateX(${rotationX}deg)`
        ),
        ["--opacity"]: transform.to(
          (_x, _y, _z, _scale, _rotationY, _rotationX, opacity) => opacity
        ),
      }}
      sx={{
        color: "textWhite",
        appearance: "none",
        border: "none",
        bg: "transparent",
        margin: 0,
        padding: 0,
        willChange: "transform",
        cursor: "pointer",
        transformStyle: "preserve-3d",
        width,
        height: width,
        position: "absolute",
        zIndex: positionY,
        transformOrigin: "center",
        pointerEvents: "all",
        ["--opacity"]: isSelected ? "1 !important" : undefined,
        ["--color-bg"]: isSelected ? colorActive : colorCarbon,

        ":focus": {
          outline: "none",
        },

        ":hover, :focus-visible": {
          ["--color-bg"]: colorActive,
          ["--opacity"]: "1 !important",
        },
      }}
    >
      <Box
        sx={{
          opacity: "var(--opacity)",
          transform: `rotateY(0deg) translateZ(${width / 2}px)`,
          ...faceStyle,
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            opacity: isActiveCareer ? 1 : 0,
            transition: "opacity 0.25s 0.25s var(--animation-bezier)",
          }}
        >
          <Face role={career === 'manager' && managerRole ? managerRole : role} level={level} />
        </Box>
      </Box>
      <Box
        sx={{
          opacity: "var(--opacity)",
          bg: "red",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: [3, 5],
          transform: `rotateY(180deg) translateZ(${width / 2}px)`,
          ...faceStyle,
        }}
      >
        <Heading
          sx={{
            opacity: isSelected ? 1 : selected !== null ? 0.4 : 0,
            fontWeight: 300,
            fontSize: [8, 12, 14, 18, 18],
            lineHeight: 1,
            color: "currentColor",

            "button:hover &": {
              opacity: 1,
            },
          }}
        >
          {level}
        </Heading>
      </Box>
      <Box
        sx={{
          opacity: "var(--opacity)",
          filter: `brightness(${isActiveCareer ? 0.8 : 0.9})`,
          transition: "filter 0.25s var(--animation-bezier)",
          transform: `rotateY(90deg) translateZ(${width / 2}px)`,
          ...faceStyle,
        }}
      />
      <Box
        sx={{
          opacity: "var(--opacity)",
          filter: `brightness(${isActiveCareer ? 0.8 : 0.9})`,
          transition: "filter 0.25s var(--animation-bezier)",
          transform: `rotateY(-90deg) translateZ(${width / 2}px)`,
          p: [3, 5],
          ...faceStyle,
        }}
      ></Box>
      <Box
        sx={{
          opacity: "var(--opacity)",
          transform: `rotateX(90deg) translateZ(${width / 2}px)`,
          ...faceStyle,
        }}
      >
        <Face role={managerRole} level={level} />
      </Box>
      <Box
        sx={{
          opacity: "var(--opacity)",
          filter: `brightness(${managerRole ? 0.9 : 0.8})`,
          transform: `rotateX(-90deg) translateZ(${width / 2}px)`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "filter 0.25s var(--animation-bezier)",
          ...faceStyle,
        }}
      >
        {managerRole && (
          <Heading
            sx={{
              opacity: isManager ? 1 : 0,
              fontWeight: 300,
              fontSize: [8, 12, 14, 18, 18],
              lineHeight: 1,
              color: "currentColor",
              transition: "opacity 0.25s 0.25s var(--animation-bezier)",
              transform: "rotate(180deg)",

              "button:hover &": {
                opacity: isManager ? 1 : 0,
                transition: "none",
              },
            }}
          >
            {level}
          </Heading>
        )}
      </Box>
    </AnimatedButton>
  )
}
