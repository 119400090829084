import * as React from "react"
import { FC } from "react"
import { Text, Box, Heading } from "theme-ui"

import { Arrow } from "components/Arrow"

import { LevelHeading } from "../LevelHeading"

export const Level7IC: FC = () => {
  return (
    <>
      <LevelHeading>
        Staff Software Engineer
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L7
        </Box>
      </LevelHeading>
      <Box sx={{ mb: [10, 12, null, 13, 14] }}>
        <Box as="ul" variant="layout.ladderList">
          <Box as="li">
            <Text variant="ladderEyebrow">Technical excellence</Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Identify and prioritizes technical improvements backed by
                  data, industry trends, experience, while considering business
                  constraints, to deliver on important initiatives
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Leads a large and complex initiative for multiple months ie:
                  introducing a new piece of technology
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Influences the sequencing series of projects/deliverables to
                  provide incremental values
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Strong opinions and knowledge about the current technology
                  landscape, influences adjacent teams' designs to promote
                  better holistic system design decisions
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Clear, crisp communication that's easy for other people
                  (including PMs/Business) to follow, can hold peers accountable
                  without placing blame
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Addresses reliability issues systematically by measuring
                  standard reliability KPIs and designing processes for
                  engineers to respond to automated alerting while keeping noise
                  low
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Is an expert in their team's business and product domain, has
                  mastery of several other domains (infra, platform, business)
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Up-to-date on technology trends, can weed through the noise to
                  find signal, and creates leverage through relevant
                  recommendations
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Solid understanding of security threats and uses this
                  knowledge in the planning phase to mitigate future threats
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text variant="ladderEyebrow">Initiative</Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Has mastery of SRE domain, often defining SLOs and SLIs,
                  mentors others on their Pod
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Guiding technical conversation towards best technical
                  solution, reducing information asymmetry through spirited
                  debate and open/honest conversations; facilitates a culture of
                  psychological safety
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Identifies systemic engineering challenges from onboarding and
                  developer experience, to system hotspots and actively working
                  to improving those areas
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text as="p" variant="ladderEyebrow">
              Influence
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Identify and prioritizes technical improvements backed by
                  data, industry trends, experience to convince leadership on
                  the best solution for problems
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Partners with pod leaders
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Help the pod start thinking about strategic challenges and how
                  they align with the team priorities and business goals.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Create content that has significant visibility, such as an
                  external publication or a conference talk
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Unblocking other teams on the related projects to be more
                  effective
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Helping improving the questions and signals we get from
                  Interviews to ensure that we have the highest technical bar at
                  Better
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Heading variant="h3" sx={{ mb: [6, 7, 5, 9, 10] }}>
        Path to Engineering Manager
      </Heading>
      <Heading variant="h4">Hired as Engineering Manager</Heading>
      <Box as="ul" variant="layout.ladderListInner">
        <Box as="li">
          <Text as="p" variant="body">
            In order to be considered being hired into Engineering Management
            role directly, candidate must have been an engineering manager for
            more than 4 years and have managed at least 5 engineers for a
            minimum of 2 years - Engineering Managers with less experience will
            start under the accelerated “Senior Software Engineer <Arrow />{" "}
            Engineering Manager” track described above and will work with hiring
            manager during the hiring process to define a plan
          </Text>
        </Box>
        <Box as="li">
          <Text as="p" variant="body">
            Expectation is to manage a 50% smaller team than in the previous
            position, enlarging the team by hiring in the first 12 months
          </Text>
        </Box>
        <Box as="li">
          <Text as="p" variant="body">
            Assigned 2-3 direct reports immediately upon joining
          </Text>
        </Box>
        <Box as="li">
          <Text as="p" variant="body">
            Should be showing all technical excellence, initiative and influence
            required for L7 Senior Software Engineers
          </Text>
        </Box>
        <Box as="li">
          <Text as="p" variant="body">
            Senior Engineering Managers / Director in the reporting line will
            create a plan and mentoring
          </Text>
        </Box>
        <Box as="li">
          <Text as="p" variant="body">
            Expected to code and be fully hands on
          </Text>
        </Box>
      </Box>
    </>
  )
}
