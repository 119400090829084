import * as React from "react"
import { FC } from "react"
import { Box } from "theme-ui"

export const Arrow: FC = () => {
  return (
    <Box
      as="svg"
      sx={{ height: "0.6em", width: "auto", display: "inline", px: 1 }}
      width="75"
      height="27"
      viewBox="0 0 75 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 13.5H73.438M73.438 13.5L61.438 1.5M73.438 13.5L61.438 25.5"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Box>
  )
}
