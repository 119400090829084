import * as React from "react"
import { FC } from "react"
import { Text, Box } from "theme-ui"

import { LevelHeading } from "../LevelHeading"

export const Level9IC: FC = () => {
  return (
    <>
      <LevelHeading>
        Principal Software Engineer
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L9
        </Box>
      </LevelHeading>
      <Box sx={{ mb: [10, 12, null, 13, 14] }}>
        <Box as="ul" variant="layout.ladderList">
          <Box as="li">
            <Text variant="ladderEyebrow">
              Technical excellence (Generalist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Trusted to have significant expertise and high judgement to
                  set the vision and design the right long term solution for a
                  large customer segment or stakeholder
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Subject matter expert or authoritative representative for a
                  particular technology or business area
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Strong understanding of how to structure communication that
                  best suited for the audience - whether it's an engineer,
                  product, executive, or a customer
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Mitigate complex risks
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Understand problems that plague several teams and able to help
                  groups build the optimal solution considering trade offs
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Able to do trade-off analysis to understand the scaling,
                  reliability &amp; security concerns of introducing the other
                  technology it Better to solve a large class of problem
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Advanced understanding of security threats, proactively
                  identifies larger classes of attack vectors, and sets aside
                  time to protect ourselves
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text variant="ladderEyebrow">
              Technical excellence (Specialist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Trusted to have significant expertise and high judgement to
                  set the vision and design the right long term solution for a
                  large customer segment or stakeholder
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Subject matter expert or authoritative representative for a
                  particular technology or business area
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Strong understanding of how to structure communication that
                  best suited for the audience - whether it's an engineer,
                  product, executive, or a customer
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Mitigate complex risks
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Understand problems that plague several teams and able to help
                  groups build the optimal solution considering trade offs
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Up-to-date on the trending changes on many related technical
                  industries
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Advanced understanding of one specific industry technology
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text variant="ladderEyebrow">Initiative</Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Promotes best practices to prevent outages from happening,
                  reduces likelihood of incident recurrence
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Setting squad standards
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Partnering with peers and leading engineers, to build a suite
                  of services or technologies that significantly increase a
                  Squad/Group's output
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text as="p" variant="ladderEyebrow">
              Influence (Generalist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Collaborates with peers to influence the Squad's technical
                  decision making in the context of business objectives
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Solid understanding of the product area and strategy covering
                  multiple team/squads end to end. Often viewed as the go to
                  expert in the area. Involved in defining solutions, shaping
                  strategy across the area, working with all stakeholders to
                  propose and lead solutions, strategically and tactically. Can
                  articulate the proposal and win buy-in naturally and
                  independently from senior leadership
                </Text>
              </Box>

              <Box as="li">
                <Text as="p" variant="body">
                  Solid understanding of the common security threats and
                  recommended solutions for those problems
                </Text>
              </Box>

              <Box as="li">
                <Text as="p" variant="body">
                  Create content that achieves significant external recognition,
                  such as a paper that is cited or a conference presentation
                  that is often mentioned
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Identify and remove bureaucracy, provide performance review
                  recommendation to respective management teams for related team
                  members when working within a project capacity.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Help close on the outstanding candidates by digging into their
                  professional and personal goals and how Better help them
                  accomplish their goals
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="li">
            <Text as="p" variant="ladderEyebrow">
              Influence (Specialist)
            </Text>
            <Box as="ul" variant="layout.ladderListInner">
              <Box as="li">
                <Text as="p" variant="body">
                  Trusted to have excellent technical judgement to the point
                  where they have full autonomy to spend their time on whatever
                  they think is most important for the business (scoped to the
                  Squad)
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Solid understanding of the product area and strategy covering
                  multiple team/squads end to end. Often viewed as the go to
                  expert in the area. Involved in defining solutions, shaping
                  strategy across the area, working with all stakeholders to
                  propose and lead solutions, strategically and tactically. Can
                  articulate the proposal and win buy-in naturally and
                  independently from senior leadership
                </Text>
              </Box>

              <Box as="li">
                <Text as="p" variant="body">
                  Leads an R&amp;D initiative around deep, complex/esoteric,
                  specialized subject matter to make a business case
                </Text>
              </Box>

              <Box as="li">
                <Text as="p" variant="body">
                  Create content that achieves significant external recognition,
                  such as a paper that is cited or a conference presentation
                  that is often mentioned
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Identify and remove bureaucracy, provide performance review
                  recommendation to respective management teams for related team
                  members when working within a project capacity.
                </Text>
              </Box>
              <Box as="li">
                <Text as="p" variant="body">
                  Help close on the outstanding candidates by digging into their
                  professional and personal goals and how Better help them
                  accomplish their goals
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
