import * as React from "react"
import { FC, ReactNode, useEffect } from "react"
import { animated, useSpring, config } from "react-spring"
import { useMeasure } from "react-use"
import { Box } from "theme-ui"

const AnimatedBox = animated(Box)

export interface IPropsPanel {
  children: ReactNode
  isSelected: boolean
  setPanelHeight: (value: number) => void
}

export const Panel: FC<IPropsPanel> = ({
  children,
  isSelected,
  setPanelHeight,
}) => {
  const [ref, { height }] = useMeasure<HTMLElement>()

  const [{ opacity }, animate] = useSpring(
    { opacity: 0, config: config.stiff },
    []
  )

  useEffect(() => {
    if (isSelected) {
      setPanelHeight(height)
      animate({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: 250,
      })
    } else {
      animate({
        from: { opacity: 1 },
        to: { opacity: 0 },
      })
    }
  }, [isSelected])

  return (
    <AnimatedBox
      sx={{
        gridColumn: ["1/11", "2/11", "3/11", "4/10"],
        gridRow: "1/2",
        pointerEvents: isSelected ? "all" : "none",
        opacity: 0,
      }}
      ref={ref}
      style={{ opacity }}
    >
      {children}
    </AnimatedBox>
  )
}
