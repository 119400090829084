import * as React from "react"
import { FC } from "react"
import { Text, Box } from "theme-ui"

import { LevelHeading } from "../LevelHeading"

export const Level5IC: FC = () => {
  return (
    <>
      <LevelHeading>
        Software Engineer
        <Box as="span" sx={{ fontWeight: 300, ml: 3 }}>
          L5
        </Box>
      </LevelHeading>
      <Box as="ul" variant="layout.ladderList">
        <Box as="li">
          <Text variant="ladderEyebrow">Technical excellence</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Shows a good understanding of the tradeoff between perfect and
                done
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Works on projects autonomously for a few weeks with little
                supervision
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Breaks their work into smaller deliverables and ships valuable
                changes incrementally in small chunks, released often
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Code follows general best practices for idioms. The system
                design matches industry standard patterns
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Generally unambiguous communication that's to the point
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Debugs complex things by cross checking and systematically
                ruling out cases by looking at logs, metrics, and other things
                in creative ways
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Understands team business and product domain well, is familiar
                with adjacent teams' business and product domain
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Solid understanding of the common security threats and
                recommended solutions for those problems
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Text variant="ladderEyebrow">Initiative</Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Handles most oncall issues independently
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Considering how standards evolve, making suggestions for
                improving code quality more broadly within the team, mentoring
                more junior engineers
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Propose solutions/improvements to existing processes
              </Text>
            </Box>
          </Box>
        </Box>
        <Box as="li">
          <Text as="p" variant="ladderEyebrow">
            Influence
          </Text>
          <Box as="ul" variant="layout.ladderListInner">
            <Box as="li">
              <Text as="p" variant="body">
                Brings observations to management and leadership of potential
                issues
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Regarded by the PM as someone who reliably ships things and can
                be trusted to make decisions
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Helping others with code-reviews, reviewing erds, and mentoring
                junior engineers
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Create content that contributes to Better's body of work and
                presence within the tech world
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Unblocking team progress through strategically executing on
                their own work
              </Text>
            </Box>
            <Box as="li">
              <Text as="p" variant="body">
                Start participating in recruiting (This includes helping out
                with karat interview review, to intern/entry level interview, to
                doing sell calls, college recruiting trips...)
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
