import * as React from "react"
import { FC, ReactNode } from "react"
import { Box, Heading, Text } from "theme-ui"

export interface IPropsFace {
  level: ReactNode
  role: ReactNode
}

export const Face: FC<IPropsFace> = ({ role, level }) => {
  return (
    <Box
      sx={{
        p: ["6px", 2, 3, 5, 6, 7],
        display: "grid",
        gridAutoFlow: "column",
        alignItems: "space-between",
        height: "100%",
      }}
    >
      <Heading
        sx={{
          wordSpacing: "0.01em",
          fontSize: ["8px", 1, 2, 3, 6, 7],
          fontWeight: 400,
          letterSpacing: "tight4",
          alignSelf: "flex-end",
          textAlign: "left",
          "::selection, div::selection, span::selection": {
            bg: "backgroundSelectionWhite",
          },
        }}
      >
        {role}
      </Heading>
      <Text
        sx={{
          color: "currentColor",
          fontSize: [1, 4, 4, 4, 6, 7],
          fontWeight: 300,
          lineHeight: 1,
          opacity: 0.6,
          pl: 2,
          textAlign: "right",
          "::selection": {
            bg: "backgroundSelectionWhite",
          },
        }}
      >
        {level}
      </Text>
    </Box>
  )
}
